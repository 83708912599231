import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import './App.css';
import logo from './logo.svg';

const onboard = Onboard({
  wallets: [injectedModule()],
  chains: [
    {
      id: '0x61',
      token: 'BNB',
      label: 'BNB Chain Testnet',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
    }
  ],
  appMetadata: {
    name: 'Parrot Protocol',
    icon: logo,
    description: 'Content Monetization Platform'
  }
});



const mockContents = [
  {
    id: 1,
    uri: "ipfs://content1",
    type: "video",
    price: ethers.utils.parseEther("0.1"),
    creator: "0x3Bf2D51c7AeF72E9F575931AbA69F624233e59aC",
    votes: 5,
  },
  {
    id: 2,
    uri: "ipfs://content2",
    type: "video",
    price: ethers.utils.parseEther("0.2"),
    creator: "0x3Bf2D51c7AeF72E9F575931AbA69F624233e59aC",
    votes: 3,
  },
  {
    id: 3,
    uri: "ipfs://content3",
    type: "image",
    price: ethers.utils.parseEther("0.3"),
    creator: "0x3Bf2D51c7AeF72E9F575931AbA69F624233e59aC",
    votes: 8,
  },
];


const App = () => {
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [contents, setContents] = useState([]);
  const [uri, setUri] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('image'); // Default content type is 'image'

  useEffect(() => {
    const connectWallet = async () => {
      const wallets = await onboard.connectWallet();
      if (wallets[0]) {
        const ethersProvider = new ethers.providers.Web3Provider(wallets[0].provider);
        const signer = ethersProvider.getSigner();
        setSigner(signer);
        const userAccount = await signer.getAddress();
        setAccount(userAccount);

        const userBalance = await ethersProvider.getBalance(userAccount);
        setBalance(ethers.utils.formatEther(userBalance));
      }
    };

    connectWallet();
    loadContents(); // Load mock contents
  }, []);

  const signMessage = async (message) => {
    if (!signer) {
      throw new Error('No signer available');
    }
    return await signer.signMessage(message);
  };

  const handleCreateContent = async () => {
    try {
      const message = `Create content: ${uri} with price ${price} and type ${type}`;
      const signature = await signMessage(message);
      const response = await axios.post(`http://localhost:3000/createContent`, {
        uri,
        price,
        type,
        signature,
        address: account
      });
      console.log(response.data);
      loadContents();
      setUri('');
      setPrice('');
      setType('image'); // Reset to default type
    } catch (error) {
      console.error('Error creating content:', error);
    }
  };

  const handlePurchaseContent = async (id, contentPrice) => {
    try {
      const message = `Purchase content ID: ${id} with price ${contentPrice}`;
      const signature = await signMessage(message);
      const response = await axios.post(`http://localhost:3000/purchaseContent`, {
        id,
        value: contentPrice,
        signature,
        address: account
      });
      console.log(response.data);
      loadContents();
    } catch (error) {
      console.error('Error purchasing content:', error);
    }
  };

  const handleVote = async (id) => {
    try {
      const message = `Vote for content ID: ${id}`;
      const signature = await signMessage(message);
      const response = await axios.post(`http://localhost:3000/vote`, {
        id,
        signature,
        address: account
      });
      console.log(response.data);
      loadContents();
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  const loadContents = async () => {
    // Using mock data for demonstration purposes
    setContents(mockContents);
  };

  const renderContentPreview = (content) => {
    const ipfsGatewayUrl = content.uri.replace('ipfs://', 'https://ipfs.io/ipfs/');
    switch (content.type) {
      case 'image':
        return <img src={ipfsGatewayUrl} alt="content" style={{ maxWidth: '300px', maxHeight: '300px' }} />;
      case 'video':
        return <video controls src={ipfsGatewayUrl} style={{ maxWidth: '300px', maxHeight: '300px' }} />;
      case 'audio':
        return <audio controls src={ipfsGatewayUrl} />;
      case 'pdf':
        return (
          <object data={ipfsGatewayUrl} type="application/pdf" width="300px" height="300px">
            <p>PDF cannot be displayed. <a href={ipfsGatewayUrl}>Download PDF</a></p>
          </object>
        );
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Parrot Protocol Logo" className="App-logo" />
        <h1>Parrot Protocol</h1>
        <button onClick={() => onboard.connectWallet()}>Connect Wallet</button>
        {account && <p>Connected Account: {account}</p>}
        {balance && <p>Balance: {balance} BNB</p>}
      </header>
      <main>
        <div className="create-content">
          <input
            type="text"
            placeholder="Content URI"
            value={uri}
            onChange={(e) => setUri(e.target.value)}
          />
          <input
            type="number"
            placeholder="Price in BNB"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="image">Image</option>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
            <option value="pdf">PDF</option>
          </select>
          <button onClick={handleCreateContent}>Create Content</button>
        </div>
        <div className="content-list">
          <h2>Active Content</h2>
          {contents.map((content) => (
            <div key={content.id} className="content-item">
              {renderContentPreview(content)}
              <p>URI: {content.uri}</p>
              <p>Price: {ethers.utils.formatEther(content.price)} BNB</p>
              <p>Votes: {content.votes}</p>
              <button onClick={() => handlePurchaseContent(content.id, ethers.utils.formatEther(content.price))}>Purchase</button>
              <button onClick={() => handleVote(content.id)}>Vote</button>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default App;
